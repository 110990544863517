import { useState, useEffect } from "react";
import fetchJson from "./fetchJson";

export const setToken = (token) => localStorage.setItem("token", token);
export const deleteToken = () => localStorage.removeItem("token");

// typeof localStorage !== "undefined" is needed for SSR
export const isAuthed = () =>
  typeof localStorage !== "undefined" && !!localStorage.getItem("token");

export const callApi = async ({ path, ...rest }) => {
  const token = localStorage.getItem("token");
  const out = await fetchJson({
    url: `/api/${path.replace(/^\//, "")}`,
    ...(token && { headers: { Authorization: `Bearer: ${token}` } }),
    ...rest,
  });
  if (out.error && out.error.statusCode === 401) {
    deleteToken();
    window.location = "/app/login";
  }
  return out;
};

export const useApi = (args) => {
  const [data, setData] = useState();
  const strArgs = JSON.stringify(args || null);
  useEffect(() => {
    let didCancel = false;
    (async () => {
      const parsedArgs = JSON.parse(strArgs);
      const result = parsedArgs ? await callApi(parsedArgs) : {};
      if (!didCancel) setData(result);
    })();

    return () => {
      didCancel = true;
    };
  }, [strArgs]);

  return data;
};
