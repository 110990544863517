import React from "react";
import { Link } from "gatsby";
import { HomeFilled } from "@ant-design/icons";
import { Button } from "antd";
import lighthouse from "../images/lighthouse.jpg";
import page from "../components/page";
import { Title } from "../components/typography";
import useTranslation from "../lang/useTranslation";
import { pY } from "../components/spacingShorthands";
import Container from "../components/Container";

export const Content404 = () => (
  <div
    css={{
      ...pY(16),
      backgroundImage: `url(${lighthouse})`,
      backgroundSize: "cover",
      backgroundPosition: "top left",
      flexGrow: 1,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
    }}
  >
    <Container>
      <Title white>{useTranslation("notFoundText")}</Title>
      <Link to="/">
        <Button size="large" type="primary" icon={<HomeFilled />}>
          Return home
        </Button>
      </Link>
    </Container>
  </div>
);

export default page(Content404);
