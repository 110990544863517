import nProgress from "nprogress";
import { useEffect } from "react";
import "./nProgress.css";

export const trackNProgress = async (promise) => {
  nProgress.start();
  try {
    const result = await (typeof promise === "function"
      ? promise(() => nProgress.inc())
      : promise);
    return result;
  } finally {
    nProgress.done();
  }
};

export const useNProgress = (data) => {
  useEffect(() => {
    nProgress[data ? "done" : "start"]();
  }, [data]);
  return data;
};
