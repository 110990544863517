export default async ({ url, method = "GET", params = {}, headers = {} }) => {
  let fullUrl = url;
  const options = { headers, method };

  if (params) {
    const paramNames = Object.keys(params);
    if (method === "GET" && paramNames.length)
      fullUrl += `?${paramNames
        .map((n) => `${encodeURIComponent(n)}=${encodeURIComponent(params[n])}`)
        .join("&")}`;
    else if (method === "POST") {
      options.body = JSON.stringify(params);
      options.headers["Content-Type"] = "application/json";
    }
  }

  return (await fetch(fullUrl, options)).json();
};
