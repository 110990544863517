import React from "react";
import openDoor from "../images/open-door.jpg";
import page from "../components/page";
import { Title, Paragraph } from "../components/typography";
import useTranslation from "../lang/useTranslation";
import { pY } from "../components/spacingShorthands";
import { useApi } from "../components/api";
import { useParams, Router, Redirect } from "../components/reach-router";
import { useNProgress } from "../components/nProgress";
import { Content404 } from "./404";
import lighthouse from "../images/lighthouse.jpg";
import Container from "../components/Container";

const Login = () => {
  const result = useNProgress(
    useApi({
      path: "users/loginRequests/confirm",
      method: "POST",
      params: useParams(),
    })
  );
  const t = useTranslation();
  if (!result) return null;
  const ok = !result.error;
  const bg = ok
    ? {
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.5), rgba(0,0,0,0.1)), url(${openDoor})`,
        backgroundPosition: "center",
      }
    : {
        backgroundImage: `url(${lighthouse})`,
        backgroundPosition: "top left",
      };
  return (
    (result || null) && (
      <div
        css={{
          ...pY(16),
          ...bg,
          backgroundSize: "cover",
          flexGrow: 1,
          textAlign: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container>
          <Title white>
            {t(ok ? "loginSuccessful" : "invalidExpiredLink")}
          </Title>
          <Paragraph white>{t("youMayClose")}</Paragraph>
        </Container>
      </div>
    )
  );
};

export default page(() => (
  <Router
    css={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
    basepath="/confirm"
  >
    <Redirect noThrow from="/" to="../" />
    <Login path="/login/:key" />
    <Content404 default />
  </Router>
));
